import { createRouter, createWebHistory } from "vue-router";
import LoginPage from "@/components/AuthFlow/LoginPage.vue";
import SignUp from "@/components/AuthFlow/SignUp.vue";
import HomePage from "@/components/UserFlow/HomePage.vue";
import AboutPage from "@/components/UserFlow/AboutPage.vue";
import ContactPage from "@/components/UserFlow/ContactPage.vue";
import QuizPage from "@/components/UserFlow/quizpage.vue";
import InbetweenQuiz from "@/components/UserFlow/InbetweenQuiz.vue";
import AdminView from "@/components/AdminFlow/AdminView.vue";
import AdminQuestions from "@/components/AdminFlow/AdminQuestion.vue";
import AdminAnswers from "@/components/AdminFlow/AdminAnswers.vue";
import AddQuestions from "@/components/AdminFlow/AdminAddQuestion.vue";
import EditQuestions from "@/components/AdminFlow/EditQuestions.vue";
import RequestReset from "@/components/AuthFlow/RequestReset.vue";
import ConfirmReset from "@/components/AuthFlow/ConfirmReset.vue";
import DiscussionQuiz from "@/components/UserFlow/DiscussionQuiz.vue";

const routes = [
  {
    path: "/",
    name: "HomePage",
    component: HomePage,
  },
  {
    path: "/About",
    name: "AboutPage",
    component: AboutPage,
  },
  {
    path: "/Contact",
    name: "ContactPage",
    component: ContactPage,
  },
  {
    path: "/Inbetween",
    name: "InbetweenQuiz",
    component: InbetweenQuiz,
    props: true,
  },
  {
    path: "/Discussion",
    name: "DiscussionQuiz",
    component: DiscussionQuiz,
    props: true,
  },
  {
    path: "/Login",
    name: "LoginPage",
    component: LoginPage,
  },
  {
    path: "/Reset",
    name: "RequestReset",
    component: RequestReset,
  },
  {
    path: "/Confirm",
    name: "ConfirmReset",
    component: ConfirmReset,
  },
  {
    path: "/Sign",
    name: "Signup",
    component: SignUp,
  },
  {
    path: "/Quiz",
    name: "QuizPage",
    component: QuizPage,
  },
  {
    path: "/Admin",
    name: "AdminView",
    component: AdminView,
  },
  {
    path: "/Admin/AdminQuestions",
    name: "AdminQuestions",
    component: AdminQuestions,
  },
  {
    path: "/Admin/AdminAnswers",
    name: "AdminAnswers",
    component: AdminAnswers,
  },
  {
    path: "/Admin/AddQuestions",
    name: "AddQuestions",
    component: AddQuestions,
  },
  {
    path: "/Admin/EditQuestions",
    name: "EditQuestions",
    component: EditQuestions,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
