<template>
  <div class="popup">
    <div class="popup-body">
      <div class="popup-image"></div>
      <div class="popup-title">
        <h3>{{ title }}</h3>
      </div>
      <div class="popup-text">
        <p>
          {{ text }}
        </p>
      </div>
      <div class="popup-button">
        <button @click="popUpFunc">Close</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["title", "text", "popUpFunc"],
};
</script>

<style scoped>
.popup {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-body {
  background: #fff;
  padding: 32px;
  border-radius: 5px;
  width: 500px;
}
</style>
