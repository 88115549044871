<template>
  <div class="body">
    <div class="spa"></div>
    <div class="pad">
      <QuizContainer />
    </div>
  </div>
</template>

<script>
import services from "@/services/services.js";
import QuizContainer from "./QuizContainer.vue";
export default {
  name: "QuizPage",
  data() {
    return {
      name: "",
    };
  },
  methods: {
    Logout() {
      localStorage.clear("Token");
      this.$router.push({ name: "HomePage" });
    },
  },
  async mounted() {
    let token = localStorage.getItem("Token");
    if (!token) {
      this.$router.push({ name: "LoginPage" });
      return;
    }
    let auth = `Token ${token}`;
    auth = auth.replaceAll('"', "");
    let result = await services.getUser(auth);
    this.name = result.data.name;
  },
  components: { QuizContainer },
};
</script>

<style scoped>
.logo {
  opacity: 0.8;
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70px;
}

.pad {
  padding: 100px;
}

.body h1 {
  font-size: 20px;
}

* {
  box-sizing: border-box;
}

body {
  background-color: #b8c6db;
  background-image: linear-gradient(315deg, #b8c6db 0%, #f5f7f7 100%);
  font-family: "Poppins", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  overflow: hidden;
  margin: 0;
}

QuizContainer {
  display: block;
  padding-top: 500px;
}
</style>
