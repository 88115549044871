<template>
  <StaticCopy />
</template>

<script>
import StaticCopy from "./StaticHomeCopy.vue";
export default {
  name: "HomePage",
  data() {
    return {
      name: "",
    };
  },
  components: { StaticCopy },
};
</script>

<style scoped>
.logo {
  opacity: 0.8;
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70px;
}

.bottom {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-top: 100px;
}

.logoutbtn {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bolder;
  color: #2691d9;
}

.body {
  margin: 80px;
}

.body h1 {
  font-size: 20px;
}

* {
  box-sizing: border-box;
}

body {
  background-color: #b8c6db;
  background-image: linear-gradient(315deg, #b8c6db 0%, #f5f7f7 100%);
  font-family: "Poppins", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  overflow: hidden;
  margin: 0;
}

button {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30%;
  height: 50px;
  border: 1px solid;
  background: #2691d9;
  border-radius: 25px;
  font-size: 14px;
  color: #e9f4fb;
  font-weight: 700;
  cursor: pointer;
  outline: none;
}

button:hover {
  border-color: #2691d9;
  transition: 0.5s;
}
</style>
