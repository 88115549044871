import axios from "axios";

const apiClient = axios.create({
  baseURL: "https://idolb.ymihere.info",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export default {
  Login(email, password) {
    const data = {
      email: email,
      password: password,
    };
    return apiClient.post("auth/token/login/", data);
  },
  reset(email) {
    const data = {
      email: email,
    };
    return apiClient.post("auth/users/reset_password/", data);
  },
  confirmReset(uid, token, password) {
    const data = {
      uid: uid,
      token: token,
      new_password: password,
    };
    return apiClient.post("auth/users/reset_password_confirm/", data);
  },
  registerStudent(name, email, password) {
    const data = {
      name: name,
      email: email,
      password: password,
      re_password: password,
      is_admin: false,
      is_student: true,
    };
    return apiClient.post("auth/users/", data);
  },
  registerAdmin(name, email, password) {
    const data = {
      name: name,
      email: email,
      password: password,
      re_password: password,
      is_admin: true,
      is_student: false,
    };
    return apiClient.post("auth/users/", data);
  },
  getUser(token) {
    return apiClient.get("auth/users/me", {
      headers: {
        Authorization: token,
      },
    });
  },
  submitResponse(token, uid, answer) {
    const data = {
      question_uid: uid,
      responseText: answer,
    };
    return apiClient.post("question/response/", data, {
      headers: {
        Authorization: token,
      },
    });
  },
  AddMCQuestion(
    token,
    questionText,
    questionType,
    isDiscussion,
    isThematic,
    option1,
    option2,
    option3,
    option4
  ) {
    const data = {
      questionText: questionText,
      questionType: questionType,
      status: true,
      is_discusstion: isDiscussion,
      is_thematic: isThematic,
      options: [
        {
          optionText: option1,
        },
        {
          optionText: option2,
        },
        {
          optionText: option3,
        },
        {
          optionText: option4,
        },
      ],
    };
    return apiClient.post("question/questions/", data, {
      headers: {
        Authorization: token,
      },
    });
  },
  AddTXQuestion(token, questionText, questionType, isDiscussion, isThematic) {
    const data = {
      questionText: questionText,
      questionType: questionType,
      status: true,
      is_discusstion: isDiscussion,
      is_thematic: isThematic,
      options: [],
    };
    return apiClient.post("question/questions/", data, {
      headers: {
        Authorization: token,
      },
    });
  },
  EditMCQuestion(
    uid,
    questionText,
    questionType,
    isDiscussion,
    isThematic,
    option1,
    option2,
    option3,
    option4
  ) {
    const data = {
      questionText: questionText,
      questionType: questionType,
      status: true,
      is_discusstion: isDiscussion,
      is_thematic: isThematic,
      options: [
        {
          optionText: option1,
        },
        {
          optionText: option2,
        },
        {
          optionText: option3,
        },
        {
          optionText: option4,
        },
      ],
    };
    return apiClient.put("question/questions/" + uid + "/", data);
  },
  EditTXQuestion(uid, questionText, questionType, isDiscussion, isThematic) {
    const data = {
      questionText: questionText,
      questionType: questionType,
      status: true,
      is_discusstion: isDiscussion,
      is_thematic: isThematic,
      options: [],
    };
    return apiClient.put("question/questions/" + uid + "/", data);
  },
  getAllQuestion() {
    return apiClient.get("question/questions/");
  },
  getAllAnswers() {
    return apiClient.get("question/all-response/");
  },
  deleteEverything(token) {
    return apiClient.delete("question/delete/", {
      headers: {
        Authorization: token,
      },
    });
  },
  getQuestion(uid) {
    return apiClient.get("question/questions/" + uid);
  },
  sendEmail(token, name, message, email) {
    const data = {
      name: name,
      message: message,
      email: email,
    };
    return apiClient.post("contact/contactformdata/", data);
  },
  getThematic(token, uid) {
    return apiClient.get("page/thematic_page/" + uid, {
      headers: {
        Authorization: token,
      },
    });
  },
  getDiscussion(token, uid) {
    return apiClient.get("page/discussion_page/" + uid, {
      headers: {
        Authorization: token,
      },
    });
  },
  getUserResponse(token, uid, userEmail) {
    return apiClient.get("question/question_response/", {
      headers: {
        Authorization: token,
      },
      params: {
        question_uid: uid,
        user: userEmail,
      },
    });
  },
};
