<template>
  <section class="showcase">
    <header>
      <h2 class="logo">.IDOL</h2>
      <MenuBar />
    </header>
    <div class="content">
      <div class="text">
        <h3>Can Questions</h3>
        <h3>help Focus Learning?</h3>
        <p>
          This interactive workshop is for people who are curious and want to
          learn as opposed to memorizing the subjects that they are studying. The
          workshop is participatory, i.e., it engages the participants to apply
          effective brain techniques to focus and learn. The learning from the
          workshop is designed to set the participants on a life-long journey of
          self-learning to become independent learners that would set them on the
          right track to succeed in life. In addition, participants will be able
          to use a questioning technique to focus their brain and learn better.
        </p>
        <a href="#" @click="routeToQuiz">Click to Start</a>
      </div>
      <div class="idolimg">
        <img src="@/assets/image.jpg" alt="" width="300px" />
      </div>
      <div class="sp"></div>
    </div>
    <!-- <ul class="social">
      <li>
        <a href="#"><img src="https://i.ibb.co/x7P24fL/facebook.png" /></a>
      </li>
      <li>
        <a href="#"><img src="https://i.ibb.co/Wnxq2Nq/twitter.png" /></a>
      </li>
      <li>
        <a href="#"><img src="https://i.ibb.co/ySwtH4B/instagram.png" /></a>
      </li>
    </ul> -->
    <div class="bottom">
      <h6>@ Idol, 2022</h6>
    </div>
  </section>
</template>

<script>
import MenuBar from "../GeneralComponents/NavMenu.vue";
export default {
  name: "StaticCopy",
  data() {
    return {
      name: "",
      isUserLoggedIn: false,
    };
  },
  methods: {
    Logout() {
      localStorage.clear("Token");
      this.$router.go();
    },
    routeToQuiz() {
      this.$router.push({ name: "QuizPage" });
    },
  },
  async mounted() {
    let token = localStorage.getItem("Token");
    if (!token) {
      this.isUserLoggedIn = false;
    } else {
      this.isUserLoggedIn = true;
    }
  },
  components: { MenuBar },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700,800,900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.bottom {
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-top: 100px;
  color: #fff;
}

header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 40px 100px;
  z-index: 1000;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}

header .logo {
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
}

header .out {
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
}

.menutoggle {
  width: 40px;
  height: 40px;
}

.toggle {
  position: relative;
  width: 60px;
  height: 60px;
  background: url(https://i.ibb.co/HrfVRcx/menu.png);
  background-repeat: no-repeat;
  background-size: 30px;
  background-position: center;
  cursor: pointer;
}

.toggle.active {
  background: url(https://i.ibb.co/rt3HybH/close.png);
  background-repeat: no-repeat;
  background-size: 25px;
  background-position: center;
  cursor: pointer;
}

.showcase {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  min-height: 100vh;
  padding: 100px;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  align-content: center;
  background: #011721;
  transition: 0.5s;
  z-index: 2;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #03a9f4;
  mix-blend-mode: overlay;
}

.showcase.active {
  right: 300px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #03a9f4;
  mix-blend-mode: overlay;
}

.text {
  position: relative;
  z-index: 10;
  margin-right: 20px;
}

.text h2 {
  font-size: 5em;
  font-weight: 800;
  color: #fff;
  line-height: 1em;
  text-transform: uppercase;
}

.text h3 {
  font-size: 4em;
  font-weight: 700;
  color: #fff;
  line-height: 1em;
  text-transform: uppercase;
}

.text p {
  font-size: 1.1em;
  color: #fff;
  margin: 20px 0;
  font-weight: 400;
  max-width: 700px;
}

.text a {
  display: inline-block;
  font-family: "Poppins", sans-serif;
  font-size: 1em;
  background: #fff;
  padding: 10px 30px;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: bolder;
  margin-top: 10px;
  color: #111;
  letter-spacing: 0.5px;
  transition: 0.2s;
  border-radius: 25px;
}

.text a:hover {
  letter-spacing: 2px;
  color: #0439ac;
  transition: 0.5s;
}

.social {
  position: absolute;
  z-index: 10;
  bottom: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.social li {
  list-style: none;
}

.social li a {
  display: inline-block;
  margin-right: 20px;
  filter: invert(1);
  transform: scale(0.5);
  transition: 0.5s;
}

.social li a:hover {
  transform: scale(0.5) translateY(-15px);
}

.content {
  display: contents;
}

.menu {
  position: absolute;
  top: 0;
  right: 0;
  width: 300px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu ul {
  position: relative;
}

.menu ul li {
  list-style: none;
}

.menu ul li a {
  text-decoration: none;
  font-size: 24px;
  color: #111;
}

.menu ul li a:hover {
  color: #03a9f4;
}

.idolimg img {
  width: 500px;
  height: 300px;
}

@media (max-width: 1260px) {

  .showcase,
  .showcase header {
    padding: 40px;
  }

  .showcase {
    align-items: center;
  }

  .text h2 {
    font-size: 4em;
  }

  .text h3 {
    font-size: 3em;
  }
}

@media (max-width: 1000px) {

  .showcase,
  .showcase header {
    padding: 40px;
  }

  .text h2 {
    font-size: 3em;
  }

  .text h3 {
    font-size: 2em;
  }

  .text p {
    font-size: .95em;
  }

  .idolimg img {
    width: 400px;
    height: 250px;
  }
}

@media (max-width: 800px) {

  .showcase,
  .showcase header {
    padding: 40px;
  }

  .text h2 {
    font-size: 3em;
  }

  .text h3 {
    font-size: 2em;
  }

  .idolimg {
    margin-bottom: 20px;
  }

  .idolimg img {
    width: 90%;
    height: 80%;
  }

  .sp {
    margin-bottom: 40px;
  }

  .content {
    display: flex;
    justify-content: center;
    flex-direction: column-reverse;
  }
}

@media (max-width: 600px) {

  .showcase,
  .showcase header {
    padding: 20px;
  }

    .sp {
    margin-bottom: 0px;
  }

  .logo {
    font-size: 1em;
  }

  .text h2 {
    font-size: 2em;
  }

  .text h3 {
    font-size: 1.5em;
  }

  .text p {
    font-size: .8em;
  }

  .link {
    font-size: .8em;
  }

  .idolimg {
    margin-bottom: 20px;
  }

  .idolimg img {
    width: 90%;
    height: 80%;
  }

  .content {
    display: flex;
    justify-content: center;
    flex-direction: column-reverse;
  }
}
</style>