<template>
  <input
    type="text"
    id="fname"
    name="firstname"
    placeholder="Your name.."
    v-model="name"
  />
  <input
    type="text"
    id="lname"
    name="lastname"
    placeholder="Your Email.."
    v-model="email"
  />
  <textarea
    id="subject"
    name="subject"
    placeholder="Write something.."
    style="height: 130px"
    v-model="messsage"
  ></textarea>

  <Button @click="sendEmail">Submit</Button>
</template>

<script>
import services from "@/services/services.js";
export default {
  name: "EmailForm",
  data() {
    return {
      name: "",
      messsage: "",
      email: "",
      isUserLoggedIn: false,
    };
  },
  methods: {
    async sendEmail() {
      let token = localStorage.getItem("Token");
      let auth = `Token ${token}`;
      auth = auth.replaceAll('"', "");
      let result = await services.sendEmail(
        auth,
        this.name,
        this.messsage,
        this.email
      );
      console.log(result);
      if (result.status === 201) {
        alert("email sent");
      }
    },
  },
};
</script>

<style scoped>
/* Style inputs with type="text", select elements and textareas */
input[type="text"],
select,
textarea {
  width: 100%;
  /* Full width */
  padding: 12px;
  /* Some padding */
  border: 1px solid #ccc;
  /* Gray border */
  border-radius: 4px;
  /* Rounded borders */
  box-sizing: border-box;
  /* Make sure that padding and width stays in place */
  margin-top: 6px;
  /* Add a top margin */
  margin-bottom: 16px;
  /* Bottom margin */
  resize: vertical;
  /* Allow the user to vertically resize the textarea (not horizontally) */
}

/* Style the submit button with a specific background color etc */
input[type="submit"] {
  background-color: #2691d9;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  width: 200px;
  font-weight: bolder;
}

/* When moving the mouse over the submit button, add a darker green color */
input[type="submit"]:hover {
  background-color: #2691d9;
}

/* Add a background color and some padding around the form */
.container {
  border-radius: 5px;
  background-color: #f2f2f2;
  padding: 20px;
}
</style>
