<template>
  <div class="navigation">
    <p class="linktext">
      <router-link class="link" :to="{ name: 'HomePage' }">Home</router-link>
    </p>
    <p class="linktext">
      <router-link class="link" :to="{ name: 'AboutPage' }"
        >About Us</router-link
      >
    </p>
    <p class="linktext">
      <router-link class="link" :to="{ name: 'ContactPage' }"
        >Contact Us</router-link
      >
    </p>
    <p class="logoutText" v-show="isUserLoggedIn" @click="Logout">
      Logout
    </p>
  </div>
</template>

<script>
export default {
  name: "MenuBar",
  data() {
    return {
      showMenu: false,
      isUserLoggedIn: false,
    };
  },
  methods: {
    toggle() {
      if (this.showMenu) {
        this.showMenu = false;
      } else {
        this.showMenu = true;
      }
    },
    Logout() {
      localStorage.clear("Token");
      this.$router.go();
    },
  },
  async mounted() {
    let token = localStorage.getItem("Token");
    if (!token) {
      this.isUserLoggedIn = false;
    } else {
      this.isUserLoggedIn = true;
    }
  },
};
</script>

<style scoped>
.menu {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
}

.navigation .linktext, .logoutText {
  display: inline;
  margin-left: 10px;
  margin-right: 10px;
}

.link, .logoutText {
  font-weight: bolder;
  color: #fff;
  text-decoration: none;
}

.navigation .linktext:hover {
  display: inline;
  font-weight: bolder;
  color: #2691d9;
}

h3 {
  widows: 5%;
}

svg {
  width: 50px;
}

@media (max-width: 600px) {
  .link {
    font-size: .8em;
  }
}

@media (max-width: 400px) {
  .link, .logoutText {
    font-size: .6em;
  }
}
</style>
