<template>
  <section class="showcase">
    <header>
      <h2 class="logo">.IDOL</h2>
      <MenuBar />
    </header>
    <div class="text">
      <h3>About Page</h3>
      <p>
        The core strength of the Institute for the Development of Online
        Learning (IDOL) is a brain engagement technique called Extending Neuron
        Clusters (ENC) - utilizing neurons to build upon existing experiences.
        Developed over the years since 1978, ENC represents a paradigm shift in
        teaching methodology by engaging the students' brain to effectively
        learn and apply the learning process to different walks of life. All
        IDOL workshop products and packages reflect this core strength. Research
        has shown this methodology to visibly change the thinking mindset of the
        participants. IDOL invites you to utilize the training programs
        developed over the years to create an efficient workforce that builds on
        a solid foundation promoting self-esteem, critical thinking, learning
        and self-development.
      </p>
    </div>
    <!-- <ul class="social">
      <li>
        <a href="#"><img src="https://i.ibb.co/x7P24fL/facebook.png" /></a>
      </li>
      <li>
        <a href="#"><img src="https://i.ibb.co/Wnxq2Nq/twitter.png" /></a>
      </li>
      <li>
        <a href="#"><img src="https://i.ibb.co/ySwtH4B/instagram.png" /></a>
      </li>
    </ul> -->
    <div class="bottom">
      <h6>@ Idol, 2022</h6>
    </div>
  </section>
</template>

<script>
import MenuBar from "../GeneralComponents/NavMenu.vue";
export default {
  name: "AboutPage",
  data() {
    return {
      name: "",
      isUserLoggedIn: false,
    };
  },
  methods: {
    Logout() {
      localStorage.clear("Token");
      this.$router.go();
    },
    routeToQuiz() {
      this.$router.push({ name: "QuizPage" });
    },
  },
  async mounted() {
    let token = localStorage.getItem("Token");
    if (!token) {
      this.isUserLoggedIn = false;
    } else {
      this.isUserLoggedIn = true;
    }
  },
  components: { MenuBar },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700,800,900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.bottom {
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-top: 100px;
  color: #fff;
}

header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 40px 100px;
  z-index: 1000;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}

header .logo {
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
}

header .out {
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
}

.menutoggle {
  width: 40px;
  height: 40px;
}

.toggle {
  position: relative;
  width: 60px;
  height: 60px;
  background: url(https://i.ibb.co/HrfVRcx/menu.png);
  background-repeat: no-repeat;
  background-size: 30px;
  background-position: center;
  cursor: pointer;
}

.toggle.active {
  background: url(https://i.ibb.co/rt3HybH/close.png);
  background-repeat: no-repeat;
  background-size: 25px;
  background-position: center;
  cursor: pointer;
}

.showcase {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  min-height: 100vh;
  padding: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #011721;
  transition: 0.5s;
  z-index: 2;
}

.showcase.active {
  right: 300px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #03a9f4;
  mix-blend-mode: overlay;
}

.text {
  position: relative;
  z-index: 10;
}

.text h2 {
  font-size: 5em;
  font-weight: 800;
  color: #fff;
  line-height: 1em;
  text-transform: uppercase;
}

.text h3 {
  font-size: 4em;
  font-weight: 700;
  color: #fff;
  line-height: 1em;
  text-transform: uppercase;
}

.text p {
  font-size: 1.1em;
  color: #fff;
  margin: 20px 0;
  font-weight: 400;
  max-width: 700px;
}

.text a {
  display: inline-block;
  font-family: "Poppins", sans-serif;
  font-size: 1em;
  background: #fff;
  padding: 10px 30px;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: bolder;
  margin-top: 10px;
  color: #111;
  letter-spacing: 0.5px;
  transition: 0.2s;
  border-radius: 25px;
}

.text a:hover {
  letter-spacing: 2px;
  color: #0439ac;
  transition: 0.5s;
}

.social {
  position: absolute;
  z-index: 10;
  bottom: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.social li {
  list-style: none;
}

.social li a {
  display: inline-block;
  margin-right: 20px;
  filter: invert(1);
  transform: scale(0.5);
  transition: 0.5s;
}

.social li a:hover {
  transform: scale(0.5) translateY(-15px);
}

.menu {
  position: absolute;
  top: 0;
  right: 0;
  width: 300px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu ul {
  position: relative;
}

.menu ul li {
  list-style: none;
}

.menu ul li a {
  text-decoration: none;
  font-size: 24px;
  color: #111;
}

.menu ul li a:hover {
  color: #03a9f4;
}

@media (max-width: 1260px) {

  .showcase,
  .showcase header {
    padding: 40px;
  }

  .showcase {
    align-items: center;
  }

  .text h2 {
    font-size: 4em;
  }

  .text h3 {
    font-size: 3em;
  }
}

@media (max-width: 1000px) {

  .showcase,
  .showcase header {
    padding: 40px;
  }

  .text h2 {
    font-size: 3em;
  }

  .text h3 {
    font-size: 2em;
  }

  .text p {
    font-size: .95em;
  }

  .idolimg img {
    width: 400px;
    height: 250px;
  }
}

@media (max-width: 800px) {

  .showcase,
  .showcase header {
    padding: 40px;
  }

  .text h2 {
    font-size: 3em;
  }

  .text h3 {
    font-size: 2em;
  }

  .idolimg {
    margin-bottom: 20px;
  }

  .idolimg img {
    width: 90%;
    height: 80%;
  }

  .sp {
    margin-bottom: 40px;
  }

  .content {
    display: flex;
    justify-content: center;
    flex-direction: column-reverse;
  }
}

@media (max-width: 600px) {

  .showcase,
  .showcase header {
    padding: 20px;
  }

    .sp {
    margin-bottom: 0px;
  }

  .logo {
    font-size: 1em;
  }

  .text h2 {
    font-size: 2em;
  }

  .text h3 {
    font-size: 1.5em;
  }

  .text p {
    font-size: .8em;
  }

  .link {
    font-size: .8em;
  }

  .idolimg {
    margin-bottom: 20px;
  }

  .idolimg img {
    width: 90%;
    height: 80%;
  }

  .content {
    display: flex;
    justify-content: center;
    flex-direction: column-reverse;
  }
}
</style>
