<template>
  <img src="@/assets/logo.svg" alt="idol" class="logo" />
  <div class="center">
    <h1>Reset Password</h1>
    <div class="border"></div>
    <div class="form">
      <div class="txt_field">
        <input type="text" v-model="uid" required />
        <span></span>
        <label>uid</label>
      </div>
      <div class="txt_field">
        <input type="text" v-model="token" required />
        <span></span>
        <label>token</label>
      </div>
      <div class="txt_field">
        <input type="password" v-model="newPass" required />
        <span></span>
        <label>new password</label>
      </div>
      <button v-if="buttonLoading"><ButtonLoader /></button>
      <button @click="Login" v-else>Request Reset</button>
    </div>
  </div>
  <div class="bottom">
    <h6>@ Idol, 2022</h6>
  </div>
</template>

<script>
import services from "@/services/services.js";
import ButtonLoader from "../GeneralComponents/ButtonLoader.vue";
export default {
  name: "ConfirmReset",
  data() {
    return {
      uid: "",
      token: "",
      newPass: "",
      buttonLoading: false,
    };
  },
  methods: {
    async Login() {
      this.buttonLoading = true;
      console.log("called");
      let result;
      try {
        result = await services.confirmReset(
          this.uid,
          this.token,
          this.newPass
        );
      } catch (err) {
        alert(err);
        this.buttonLoading = false;
      }
      this.buttonLoading = false;
      if (result.status === 204) {
        alert("Password Reset Successful, Routing to Login");
        this.$router.push({ name: "LoginPage" });
      }
      console.log(result);
      this.uid = "";
      this.token = "";
      this.newPass = "";
    },
  },
  mounted() {
    let token = localStorage.getItem("Token");
    if (token) {
      this.$router.push({ name: "HomePage" });
    }
  },
  components: { ButtonLoader },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@700&family=Poppins:wght@400;500;600&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.bg {
  background: linear-gradient(120deg, #2980b9, #8e44ad);
}

template {
  margin: 0;
  padding: 0;
  height: 100vh;
  overflow: hidden;
}

.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  height: 500px;
  background: white;
  border-radius: 10px;
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.05);
}

.bottom {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.bottom h6 {
  color: #333;
}

.logo {
  opacity: 0.8;
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70px;
}

.center h1 {
  text-align: center;
  padding: 20px 0;
  border-bottom: 1px solid #f0f0f0;
}

.center .form {
  padding: 0 40px;
  box-sizing: border-box;
}

.form .txt_field {
  position: relative;
  border-bottom: 1px solid #f0f0f0;
  margin: 30px 0;
}

.txt_field input {
  width: 100%;
  padding: 0 5px;
  height: 40px;
  font-size: 16px;
  border: none;
  background: none;
  outline: none;
}

.txt_field label {
  position: absolute;
  top: 50%;
  left: 5px;
  color: #adadad;
  transform: translateY(-50%);
  font-size: 16px;
  pointer-events: none;
  transition: 0.5s;
}

.txt_field span::before {
  content: "";
  position: absolute;
  top: 40px;
  left: 0;
  width: 0%;
  height: 2px;
  background: #2691d9;
  transition: 0.5s;
}

.txt_field input:focus ~ label,
.txt_field input:valid ~ label {
  top: -5px;
  color: #2691d9;
}

.txt_field input:focus ~ span::before,
.txt_field input:valid ~ span::before {
  width: 100%;
}

.pass {
  margin: -5px 0 20px 5px;
  color: #a6a6a6;
  cursor: pointer;
}

.pass:hover {
  text-decoration: underline;
}

button {
  width: 100%;
  height: 50px;
  border: 1px solid;
  background: #2691d9;
  border-radius: 25px;
  font-size: 18px;
  color: #e9f4fb;
  font-weight: 700;
  cursor: pointer;
  outline: none;
}

button:hover {
  border-color: #2691d9;
  transition: 0.5s;
}

.signup_link {
  margin: 30px 0;
  text-align: center;
  font-size: 16px;
  color: #666666;
}

.signup_link a {
  color: #2691d9;
  text-decoration: none;
}

.signup_link a:hover {
  text-decoration: underline;
}

.link a {
  color: #2691d9;
  text-decoration: none;
}

.link a:hover {
  text-decoration: underline;
}
</style>
