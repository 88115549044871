<template>
  <div class="loader" v-if="loading">
    <LoadingSpinner />
  </div>
  <div class="center" v-else>
    <div class="filterables">
      <div class="student">
        <div class="txt_field">
          <input type="text" v-model="student" required />
          <span></span>
          <label>Student</label>
        </div>
        <div class="sbutton">
          <button v-if="studentbuttonLoading"><ButtonLoader /></button>
          <button @click="filterStudent" v-else>filter by Student</button>
        </div>
      </div>
      <div class="question">
        <div class="txt_field">
          <input type="text" v-model="question" required />
          <span></span>
          <label>Question</label>
        </div>
        <div class="qbutton">
          <button v-if="questionbuttonLoading"><ButtonLoader /></button>
          <button @click="filterQuestion" v-else>filter by Question</button>
        </div>
      </div>
      <div class="reset" v-show="filtered">
        <button v-if="questionbuttonLoading"><ButtonLoader /></button>
        <button @click="resetFilter" v-else>Reset</button>
      </div>
    </div>
    <table id="keywords" cellspacing="0" cellpadding="0">
      <thead>
        <tr>
          <th><span>Student Email</span></th>
          <th><span>Question</span></th>
          <th><span>Question Type</span></th>
          <th><span>Response</span></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(res, questionindex) in result" :key="questionindex">
          <td class="lalign">{{ res.user }}</td>
          <td class="lalign">{{ res.question.questionText }}</td>
          <td class="lalign">{{ res.question.questionType }}</td>
          <td class="lalign">{{ res.responseText }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import services from "@/services/services.js";
import ButtonLoader from "../GeneralComponents/ButtonLoader.vue";
import LoadingSpinner from "./LoadingSpinner.vue";
export default {
  name: "AllAnswers",
  data() {
    return {
      amount: 0,
      result: null,
      proxy: null,
      loading: false,
      student: "",
      question: "",
      studentbuttonLoading: false,
      questionbuttonLoading: false,
      filtered: false,
    };
  },
  methods: {
    getQuestionName(uid) {
      console.log(uid);
      return "Question Name";
    },
    filterStudent() {
      this.result = this.result.filter(this.filterFuncStudent);
      this.filtered = true;
      this.student = "";
    },
    filterFuncStudent(result) {
      return result.user === this.student;
    },
    filterFuncQuestion(result) {
      console.log(result);
      return result.question.questionText === this.question;
    },
    filterQuestion() {
      this.result = this.result.filter(this.filterFuncQuestion);
      this.filtered = true;
      this.question = "";
    },
    resetFilter() {
      this.result = this.proxy;
    },
  },
  async mounted() {
    this.loading = true;
    let result = await services.getAllAnswers();
    this.result = result.data.data;
    this.proxy = this.result;
    this.amount = this.result.length;
    this.loading = false;
  },
  components: { LoadingSpinner, ButtonLoader },
};
</script>

<style scoped>
.quiz-container {
  display: block;
  width: 400px;
  background: white;
  border-radius: 10px;
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.05);
  width: 600px;
  overflow: hidden;
}

.center {
  display: grid;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.contain {
  width: 400px;
  background: white;
  border-radius: 10px;
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.05);
  width: 600px;
  overflow: hidden;
  margin-bottom: 20px;
  margin-right: 20px;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.quiz-header {
  padding: 4rem;
}

.form .txt_field {
  position: relative;
  border-bottom: 1px solid #f0f0f0;
  margin: 30px 0;
}

.txt_field input {
  width: 50%;
  padding: 0 5px;
  height: 40px;
  font-size: 16px;
  border: none;
  background: none;
  outline: none;
}

.txt_field label {
  position: absolute;
  top: 50%;
  left: 5px;
  color: #adadad;
  transform: translateY(-50%);
  font-size: 16px;
  pointer-events: none;
  transition: 0.5s;
}

.txt_field span::before {
  content: "";
  position: absolute;
  top: 40px;
  left: 0;
  width: 0%;
  height: 2px;
  background: #2691d9;
  transition: 0.5s;
}

.txt_field input:focus ~ label,
.txt_field input:valid ~ label {
  top: -5px;
  color: #2691d9;
}

.txt_field input:focus ~ span::before,
.txt_field input:valid ~ span::before {
  width: 50%;
}

h2 {
  padding: 1rem;
  text-align: center;
  margin: 0;
}

input[type="radio"] {
  width: 16px;
  height: 16px;
  margin-right: 20px;
}

input[type="radio"]:checked {
  background: #2691d9;
}

input[type="checkbox"] {
  box-sizing: border-box;
  appearance: none;
  background: white;
  outline: 2px solid #333;
  border: 3px solid white;
  width: 16px;
  height: 16px;
  margin-right: 20px;
}

input[type="checkbox"]:checked {
  background: #2691d9;
}

ul {
  list-style-type: none;
  padding: 0;
}

ul li {
  font-size: 1.2rem;
  margin: 1rem 0;
}

ul li label {
  cursor: pointer;
}

button {
  background-color: #2691d9;
  color: #fff;
  border: none;
  display: block;
  width: 100%;
  cursor: pointer;
  font-size: 1.1rem;
  font-family: inherit;
  padding: 1.3rem;
  border-radius: 0;
}

button:hover {
  background-color: #2691d9;
  transition: 0.5s;
}

button:focus {
  outline: none;
  background-color: #2691d9;
}

/** page structure **/

#keywords {
  margin: 0 auto;
  font-size: 1.2em;
  margin-bottom: 15px;
}

#keywords thead {
  cursor: pointer;
  background: #c9dff0;
}
#keywords thead tr th {
  font-weight: bold;
  padding: 12px 30px;
  padding-left: 42px;
}
#keywords thead tr th span {
  padding-right: 20px;
  background-repeat: no-repeat;
  background-position: 100% 100%;
}

#keywords thead tr th.headerSortUp,
#keywords thead tr th.headerSortDown {
  background: #acc8dd;
}

#keywords thead tr th.headerSortUp span {
  background-image: url("https://i.imgur.com/SP99ZPJ.png");
}
#keywords thead tr th.headerSortDown span {
  background-image: url("https://i.imgur.com/RkA9MBo.png");
}

#keywords tbody tr {
  color: #555;
}
#keywords tbody tr td {
  text-align: center;
  padding: 15px 10px;
}
#keywords tbody tr td.lalign {
  text-align: left;
}
.filterables {
  padding-top: 20px;
  display: flex;
  gap: 50px;
  position: relative;
  padding-bottom: 20px;
}
.student {
  display: flex;
  gap: 50px;
  position: relative;
}

.question {
  display: flex;
  gap: 50px;
  position: relative;
}

.sbutton,
.qbutton {
  padding-left: 20px;
}
</style>
