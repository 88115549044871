<template>
  <div class="loader" v-if="loading">
    <LoadingSpinner />
  </div>
  <div class="themcon" id="quiz" v-else>
    <h2>Discussion Page</h2>
    <p v-show="showText">
      {{ this.textContent[0] }}
    </p>
    <div class="loop" v-for="(iter, index) in discussion.video" :key="index">
      <iframe width="420" height="200" :src="discussion.video[index].src" allowfullscreen>
      </iframe>
    </div>
    <div class="loop" v-for="(iter, index) in discussion.image" :key="index">
      <img :src="discussion.image[index].src" alt="" />
    </div>
    <div class="response" v-if="showAnswer">
      <p class="responseText">
        {{ response[response.length - 1].responseText }}
      </p>
    </div>
    <div v-else></div>
    <button id="submit" v-if="buttonLoading">
      <ButtonLoader />
    </button>
    <button id="submit" @click="reroute" v-else>Continue</button>
  </div>
</template>
<script>
import services from "@/services/services.js";
import LoadingSpinner from "../GeneralComponents/LoadingSpinner.vue";
import ButtonLoader from "../GeneralComponents/ButtonLoader.vue";
export default {
  name: "DiscussionQuiz",
  data() {
    return {
      uid: null,
      isText: null,
      replacementUid: null,
      count: null,
      discussion: null,
      response: null,
      loading: false,
      buttonLoading: false,
      showAnswer: false,
      textContent: null,
      showText: false,
      isThematic: false,
      isDiscussion: false,
    };
  },
  methods: {
    async reroute() {
      if (this.isThematic === false && this.isDiscussion === false) {
        let count = localStorage.getItem("count");
        if (count === 0) {
          alert("Segment Finished");
          this.$router.push({ name: "HomePage" });
        } else {
          this.$router.push({ name: "QuizPage" });
        }
      } else if (this.isThematic === true) {
        await this.$router.push({
          name: "InbetweenQuiz",
          query: { uid: this.discussion.uuid },
        });
      } else if (this.isDiscussion === true) {
        await this.$router.push({
          name: "DiscussionQuiz",
          query: {
            uid: this.replacementUid,
            isText: "false",
          },
        });
        this.reload();
        return;
      }
    },
    async reload() {
      this.loading = true;
      this.uid = this.$route.query.uid;
      this.isText = this.$route.query.isText;
      this.discussion = null;
      let token = localStorage.getItem("Token");
      let auth = `Token ${token}`;
      auth = auth.replaceAll('"', "");
      let discussion = await services.getDiscussion(auth, this.uid);
      if (discussion.status === 200) {
        this.discussion = discussion.data;
        this.replacementUid = this.discussion.uuid;
        this.isThematic = this.discussion.is_thematical;
        this.isDiscussion = this.discussion.is_discussion;
      }
      this.textContent = this.discussion.textContent;
      if (this.textContent.length != 0) {
        this.showText = true;
      }
      this.loading = false;
    },
  },
  async created() {
    this.loading = true;
    this.uid = this.$route.query.uid;
    this.isText = this.$route.query.isText;
    // fetching and cleaning auth token
    if (this.isText === "true") {
      this.showAnswer = true;
    }
    let token = localStorage.getItem("Token");
    let auth = `Token ${token}`;
    auth = auth.replaceAll('"', "");
    let discussion = await services.getDiscussion(auth, this.uid);
    if (discussion.status === 200) {
      this.discussion = discussion.data;
      this.replacementUid = this.discussion.uuid;
      this.isThematic = this.discussion.is_thematical;
      this.isDiscussion = this.discussion.is_discussion;
    }
    this.textContent = this.discussion.textContent;
    if (this.textContent.length != 0) {
      this.showText = true;
    }
    if (this.showAnswer === true) {
      let email = localStorage.getItem("email");
      email = email.replaceAll('"', "");
      console.log(email);
      let responses = await services.getUserResponse(auth, this.uid, email);
      if (responses.status === 200) {
        this.response = responses.data.data;
      }
    }
    this.loading = false;
  },
  components: { LoadingSpinner, ButtonLoader },
};
</script>

<style scoped>
.themcon {
  display: grid;
  align-items: center;
  justify-content: center;
  justify-items: center;
}

.loop {
  display: grid;
  grid-template-areas: "a";
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.response {
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}

.responseText {
  display: block;
  line-height: 40px;
  font-size: 18px;
  font-family: sans-serif;
  text-decoration: none;
  color: #333;
  border-width: 3px;
  border-style: solid;
  border-color: #333;
  width: 300px;
  padding: 20px;
  letter-spacing: 2px;
  text-align: center;
  position: relative;
  transition: all 0.35s;
}

.quiz-header {
  padding: 4rem;
}

h2 {
  padding: 20px;
  margin-top: 50px;
}

img {
  width: 600px;
  height: 400px;
}

.discussion {
  padding: 20px;
  font-size: 40px;
}

iframe {
  width: 600px;
  height: 400px;
  margin-top: 20px;
  margin-bottom: 20px;
}

button {
  background-color: #2691d9;
  color: #fff;
  border: none;
  display: block;
  width: 50%;
  cursor: pointer;
  font-size: 1.1rem;
  font-family: inherit;
  padding: 1.3rem;
  border-radius: 0;
  margin-top: 50px;
  margin-bottom: 50px;
}

button:hover {
  background-color: #2691d9;
  transition: 0.5s;
}

button:focus {
  outline: none;
  background-color: #2691d9;
}

@media (max-width: 600px) {
  iframe {
    width: 100%;
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
  }

  .responseText {
    font-size: .6em;
    line-height: 10px;
    padding: 10px;
  }
}
</style>
