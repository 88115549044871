<template>
  <div class="loader" v-if="loading">
    <LoadingSpinner />
  </div>
  <div class="center" v-else>
    <div
      class="contain"
      id="quiz"
      v-for="(res, questionindex) in result"
      :key="questionindex"
    >
      <div class="quiz-header">
        <h4>{{ questionindex + 1 }} / {{ amount }}</h4>
        <div class="button" :key="questionindex">
          <Button @click="routeEdit(questionindex)">Edit this Question</Button>
        </div>
        <h2 id="question">{{ res.questionText }}</h2>
        <ul>
          <p v-if="result[questionindex].options.length > 1">Options</p>
          <p v-else>Text Question</p>
          <li
            v-for="(option, index) in result[questionindex].options"
            :key="index"
          >
            <label for="a" id="a_text"> {{ option.optionText }}</label>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import services from "@/services/services.js";
import LoadingSpinner from "./LoadingSpinner.vue";
export default {
  name: "AllQuestions",
  data() {
    return {
      amount: 0,
      result: null,
      loading: false,
    };
  },
  methods: {
    routeEdit(index) {
      localStorage.setItem("questionCount", index);
      this.$router.push({ name: "EditQuestions" });
    },
  },
  async mounted() {
    this.loading = true;
    let result = await services.getAllQuestion();
    this.result = result.data;
    this.amount = this.result.length;
    this.loading = false;
  },
  components: { LoadingSpinner },
};
</script>

<style>
.quiz-container {
  display: block;
  width: 400px;
  background: white;
  border-radius: 10px;
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.05);
  width: 600px;
  overflow: hidden;
}

.center {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.contain {
  width: 400px;
  background: white;
  border-radius: 10px;
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.05);
  width: 600px;
  overflow: hidden;
  margin-bottom: 20px;
  margin-right: 20px;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.quiz-header {
  padding: 4rem;
}

.form .txt_field {
  position: relative;
  border-bottom: 1px solid #f0f0f0;
  margin: 30px 0;
}

.txt_field input {
  width: 100%;
  padding: 0 5px;
  height: 40px;
  font-size: 16px;
  border: none;
  background: none;
  outline: none;
}

.txt_field label {
  position: absolute;
  top: 50%;
  left: 5px;
  color: #adadad;
  transform: translateY(-50%);
  font-size: 16px;
  pointer-events: none;
  transition: 0.5s;
}

.txt_field span::before {
  content: "";
  position: absolute;
  top: 40px;
  left: 0;
  width: 0%;
  height: 2px;
  background: #2691d9;
  transition: 0.5s;
}

.txt_field input:focus ~ label,
.txt_field input:valid ~ label {
  top: -5px;
  color: #2691d9;
}

.txt_field input:focus ~ span::before,
.txt_field input:valid ~ span::before {
  width: 100%;
}

h2 {
  padding: 1rem;
  text-align: center;
  margin: 0;
}

input[type="radio"] {
  width: 16px;
  height: 16px;
  margin-right: 20px;
}

input[type="radio"]:checked {
  background: #2691d9;
}

input[type="checkbox"] {
  box-sizing: border-box;
  appearance: none;
  background: white;
  outline: 2px solid #333;
  border: 3px solid white;
  width: 16px;
  height: 16px;
  margin-right: 20px;
}

input[type="checkbox"]:checked {
  background: #2691d9;
}

ul {
  list-style-type: none;
  padding: 0;
}

ul li {
  font-size: 1.2rem;
  margin: 1rem 0;
}

ul li label {
  cursor: pointer;
}

button {
  background-color: #2691d9;
  color: #fff;
  border: none;
  display: block;
  width: 100%;
  cursor: pointer;
  font-size: 1.1rem;
  font-family: inherit;
  padding: 1.3rem;
  border-radius: 0;
}

button:hover {
  background-color: #2691d9;
  transition: 0.5s;
}

button:focus {
  outline: none;
  background-color: #2691d9;
}

.button {
  position: relative;
  padding-top: 20px;
}
</style>
