<template>
  <div class="loader" v-if="loading">
    <LoadingSpinner />
  </div>
  <div class="quiz-container" id="quiz" v-else>
    <div class="quiz-header">
      <h4>{{ count + 1 }} / {{ amount }}</h4>
      <h2 id="question">{{ questionText }}</h2>
      <h6 id="question" v-show="showText">{{ textContent }}</h6>
      <h6 id="question" v-show="showText">{{ textContent2 }}</h6>
      <img :src="image" alt="" v-show="showImage" />
      <iframe width="420" height="200" :src="video" v-show="showVideo">
      </iframe>
      <ul v-if="multiple">
        <li v-for="(option, index) in options" :key="index" @click="selectAnswer(index)" allowfullscreen>
          <input
            :type="type"
            name="answer"
            id="a"
            class="answer"
          />
          <label for="a" id="a_text"> {{ option.optionText }}</label>
        </li>
      </ul>
      <div class="form" v-else>
        <div class="txt_field">
          <input type="text" v-model="responseText" required />
          <span></span>
          <label>Your Answer</label>
        </div>
      </div>
    </div>
    <button id="submit" v-if="buttonLoading">
      <ButtonLoader />
    </button>
    <button id="submit" @click="increment" v-else>Submit</button>
  </div>
</template>

<script>
import services from "@/services/services.js";
import LoadingSpinner from "../GeneralComponents/LoadingSpinner.vue";
import ButtonLoader from "../GeneralComponents/ButtonLoader.vue";
export default {
  name: "QuizContainer",
  data() {
    return {
      count: null,
      amount: 0,
      questionText: "",
      questionType: "",
      result: null,
      options: null,
      multiple: true,
      responseText: "",
      textAnswer: "",
      type: "radio",
      loading: false,
      buttonLoading: false,
      isThematic: false,
      isDiscussion: false,
      routeAgain: false,
      showImage: false,
      image: null,
      showVideo: false,
      video: null,
      showText: false,
      showText2: false,
      textContent: null,
      textContent2: null,
    };
  },
  methods: {
    async increment() {
      // initiating button loader
      this.buttonLoading = true;
      // checking for valid response
      if (this.responseText === "") {
        alert("select an option");
        this.buttonLoading = false;
        return;
      }
      // checking for rerouting to thematical or discussion property
      if (this.isThematic || this.isDiscussion) {
        if (this.isThematic) {
          this.$router.push({
            name: "InbetweenQuiz",
            query: { uid: this.result[this.count].uuid },
          });
        } else if (this.isDiscussion) {
          this.$router.push({
            name: "DiscussionQuiz",
            query: {
              uid: this.result[this.count].uuid,
              isText: this.questionType === "Text" ? true : false,
            },
          });
        }
      }
      // fetching and cleaning auth token
      let token = localStorage.getItem("Token");
      let auth = `Token ${token}`;
      auth = auth.replaceAll('"', "");
      // submitting answer and fetching result
      let result;
      try {
        result = await services.submitResponse(
          auth,
          this.result[this.count].uuid,
          this.responseText
        );
      } catch (err) {
        alert("Question Response Server Down");
        this.buttonLoading = false;
      }
      // stopping button loader
      this.buttonLoading = false;
      // incrementing all question properties
      if (result.status == 201) {
        if (this.count < this.result.length - 1) {
          this.count = this.count + 1;
          localStorage.setItem("count", this.count);
          this.questionText = this.result[this.count].questionText;
          this.questionType = this.result[this.count].questionType;
          this.options = this.result[this.count].options;
          this.isThematic = this.result[this.count].is_thematic;
          this.isDiscussion = this.result[this.count].is_discusstion;
          if (this.result[this.count].image != null) {
            if (this.result[this.count].image.length > 0) {
              this.showImage = true;
              this.image = this.result[this.count].image.src;
            }
          } else {
            this.showImage = false;
          }
          if (this.result[this.count].video != null) {
            this.showVideo = true;
            this.video = this.result[this.count].video[0].src;
          } else {
            this.showVideo = false;
          }
          if (this.result[this.count].textContent != null) {
            if (this.result[this.count].textContent.length > 1) {
              this.showText = true;
              this.showText2 = true;
              this.textContent = this.result[this.count].textContent[0].text;
              this.textContent2 = this.result[this.count].textContent[1].text;
            } else {
              this.showText = true;
              this.textContent = this.result[this.count].textContent[0].text;
            }
          } else {
            this.showText = false;
            this.showText2 = false;
          }
          // checking for singular multiple and text questions
          if (this.options.length === 0) {
            this.multiple = false;
          } else {
            this.multiple = true;
            if (this.questionType === "Multiple") {
              this.type = "checkbox";
            } else {
              this.type = "radio";
            }
          }
        } else {
          alert("All questions solved");
          localStorage.setItem("count", 0);
          if (this.isThematic === false && this.isDiscussion === false) {
            this.$router.push({ name: "HomePage" });
          }
        }
      } else {
        alert("something went wrong");
        return;
      }
      // cleaning checked options
      var ele = document.getElementsByName("answer");
      for (var i = 0; i < ele.length; i++) ele[i].checked = false;
      // cleaning answer text
      this.responseText = "";
    },
    // function to set response based on question type
    selectAnswer(index) {
      if (this.questionType === "Multiple") {
        if (this.responseText === "") {
          this.responseText = this.options[index].optionText;
        } else {
          this.responseText =
            this.responseText + ", " + this.options[index].optionText;
        }
      } else {
        this.responseText = this.options[index].optionText;
      }
    },
  },
  async mounted() {
    // initiating button loader
    this.loading = true;
    // fetching and checking count
    let count = localStorage.getItem("count");
    if (!count) {
      this.count = 0;
    } else {
      this.count = parseInt(count);
    }
    // fetching and setting questions
    let result = await services.getAllQuestion();
    this.result = result.data;
    this.amount = this.result.length;
    this.questionText = this.result[this.count].questionText;
    this.questionType = this.result[this.count].questionType;
    this.options = this.result[this.count].options;
    this.isThematic = this.result[this.count].is_thematic;
    this.isDiscussion = this.result[this.count].is_discusstion;
    if (this.result[this.count].image != null) {
      if (this.result[this.count].image.length > 0) {
        this.showImage = true;
        this.image = this.result[this.count].image.src;
      }
    } else {
      this.showImage = false;
    }
    if (this.result[this.count].video != null) {
      this.showVideo = true;
      this.video = this.result[this.count].video[0].src;
    } else {
      this.showVideo = false;
    }
    if (this.result[this.count].textContent != null) {
      if (this.result[this.count].textContent.length > 1) {
        this.showText = true;
        this.showText2 = true;
        this.textContent = this.result[this.count].textContent[0].text;
        this.textContent2 = this.result[this.count].textContent[1].text;
      } else {
        this.showText = true;
        this.textContent = this.result[this.count].textContent[0].text;
      }
    } else {
      this.showText = false;
      this.showText2 = false;
    }
    if (this.options.length === 0) {
      this.multiple = false;
    } else {
      this.multiple = true;
      if (this.questionType === "Multiple") {
        this.type = "checkbox";
      } else {
        this.type = "radio";
      }
    }
    this.loading = false;
  },
  components: { LoadingSpinner, ButtonLoader },
};
</script>

<style scoped>
.quiz-container {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background: white;
  border-radius: 10px;
  width: 600px;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.quiz-header {
  padding: 2rem;
}

.form .txt_field {
  position: relative;
  border-bottom: 1px solid #f0f0f0;
  margin: 30px 0;
}

img {
  width: 200px;
  height: 150px;
}

.discussion {
  padding: 20px;
  font-size: 10px;
}

iframe {
  width: 500px;
  height: 300px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.txt_field input {
  width: 100%;
  padding: 0 5px;
  height: 40px;
  font-size: 16px;
  border: none;
  background: none;
  outline: none;
}

.txt_field label {
  position: absolute;
  top: 50%;
  left: 5px;
  color: #adadad;
  transform: translateY(-50%);
  font-size: 16px;
  pointer-events: none;
  transition: 0.5s;
}

.txt_field span::before {
  content: "";
  position: absolute;
  top: 40px;
  left: 0;
  width: 0%;
  height: 2px;
  background: #2691d9;
  transition: 0.5s;
}

.txt_field input:focus ~ label,
.txt_field input:valid ~ label {
  top: -5px;
  color: #2691d9;
}

.txt_field input:focus ~ span::before,
.txt_field input:valid ~ span::before {
  width: 100%;
}

h2 {
  padding: 1rem;
  text-align: center;
  margin: 0;
}

input[type="radio"] {
  width: 16px;
  height: 16px;
  margin-right: 20px;
}

input[type="radio"]:checked {
  background: #2691d9;
}

input[type="checkbox"] {
  box-sizing: border-box;
  appearance: none;
  background: white;
  outline: 2px solid #333;
  border: 3px solid white;
  width: 16px;
  height: 16px;
  margin-right: 20px;
}

input[type="checkbox"]:checked {
  background: #2691d9;
}

ul {
  list-style-type: none;
  padding: 0;
}

ul li {
  font-size: 1.2rem;
  margin: 1rem 0;
}

ul li label {
  cursor: pointer;
}

button {
  background-color: #2691d9;
  color: #fff;
  border: none;
  display: block;
  width: 100%;
  cursor: pointer;
  font-size: 1.1rem;
  font-family: inherit;
  padding: 1.3rem;
  border-radius: 0;
}

button:hover {
  background-color: #2691d9;
  transition: 0.5s;
}

button:focus {
  outline: none;
  background-color: #2691d9;
}

@media (max-width: 1000px) {
  .quiz-container {
    width: 80%;
  }
}

@media (max-width: 600px) {
  h2 {
    font-size: .8em;
  }

  ul li label {
    font-size: .6em;
  }

  h6, ul {
    margin: 0px;
  }

  iframe {
    width: 100%;
    height: 100%;
    margin-bottom: 0px;
  }

  img {
    width: 100%;
    height: 100%;
    margin-bottom: 0px;
  }
}
</style>
