<template>
  <div class="body">
    <div class="hero">
      <img src="@/assets/logo.svg" alt="idol" class="logo" />
      <AdminNav />
    </div>
    <h1 class="start">All Questions</h1>
    <AllQuestions />
    <div class="bottom">
      <p class="logoutbtn" @click="Logout">Logout</p>
      <h6>@ Idol, 2022</h6>
    </div>
  </div>
</template>

<script>
import services from "@/services/services.js";
import AllQuestions from "../GeneralComponents/AllQuestions.vue";
import AdminNav from "../GeneralComponents/AdminNav.vue";
export default {
  name: "AdminQuestions",
  data() {
    return {
      name: "",
    };
  },
  methods: {
    Logout() {
      localStorage.clear("Token");
      this.$router.push({ name: "HomePage" });
    },
  },
  async mounted() {
    let token = localStorage.getItem("Token");
    if (!token) {
      this.$router.push({ name: "LoginPage" });
      return;
    }
    let auth = `Token ${token}`;
    auth = auth.replaceAll('"', "");
    let result = await services.getUser(auth);
    if (result.data.is_admin === false) {
      this.$router.push({ name: "HomePage" });
      return;
    }
    this.name = result.data.name;
  },
  components: { AllQuestions, AdminNav },
};
</script>

<style scoped>
.logo {
  opacity: 0.8;
  width: 70px;
}

.hero {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 40%;
  margin-bottom: 40px;
}

.start {
  margin-top: 40px;
}

AdminNav {
  position: relative;
}

.bottom {
  color: #222;
  position: relative;
  bottom: -40px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.logoutbtn {
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bolder;
  color: #2691d9;
}

.body {
  margin: 80px;
}

.body h1 {
  font-size: 20px;
}

* {
  box-sizing: border-box;
}

body {
  background-color: #b8c6db;
  background-image: linear-gradient(315deg, #b8c6db 0%, #f5f7f7 100%);
  font-family: "Poppins", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  overflow: hidden;
  margin: 0;
}
</style>
