<template>
  <div class="center">
    <div class="form">
      <div class="txt_field">
        <input type="text" v-model="questionText" required />
        <span></span>
        <label>Question</label>
      </div>
      <select class="custom-select" v-model="questionType">
        <option>Select a Question Type</option>
        <option>Text Answer</option>
        <option>MCQ Single Answer</option>
        <option>MCQ Multiple Answer</option>
      </select>
      <div class="txt_field" v-show="questionType != 'Text Answer'">
        <input type="text" v-model="option1" required />
        <span></span>
        <label>Option One</label>
      </div>
      <div class="txt_field" v-show="questionType != 'Text Answer'">
        <input type="text" v-model="option2" required />
        <span></span>
        <label>Option Two</label>
      </div>
      <div class="txt_field" v-show="questionType != 'Text Answer'">
        <input type="text" v-model="option3" required />
        <span></span>
        <label>Option Three</label>
      </div>
      <div class="txt_field" v-show="questionType != 'Text Answer'">
        <input type="text" v-model="option4" required />
        <span></span>
        <label>Option Four</label>
      </div>
      <div class="options">
        <div class="space" v-show="questionType === 'Text Answer'"></div>
        <input type="radio" name="thematic" @click="toggleThematic" />
        <label for="html">Has Thematic</label><br />
        <input type="radio" name="discussion" @click="toggleDiscussion" />
        <label for="html">Has Discussion</label><br />
      </div>
      <button v-if="buttonLoading">
        <ButtonLoader />
      </button>
      <button @click="addQuestion" v-else>Add Question</button>
    </div>
  </div>
</template>

<script>
import services from "@/services/services.js";
import ButtonLoader from "../GeneralComponents/ButtonLoader.vue";
export default {
  name: "AddAQuestion",
  data() {
    return {
      buttonLoading: false,
      questionText: "",
      questionType: "Select a Question Type",
      status: true,
      is_discusstion: false,
      is_thematic: false,
      option1: "",
      option2: "",
      option3: "",
      option4: "",
    };
  },
  methods: {
    async addQuestion() {
      this.buttonLoading = true;
      console.log(this.questionType);
      if (this.questionText === "") {
        alert("Please Put a Question Text");
        this.buttonLoading = false;
        return;
      }
      if (this.questionType === "Select a Question Type") {
        alert("Please Select a Question Type");
        this.buttonLoading = false;
        return;
      }
      // fetching and cleaning auth token
      let token = localStorage.getItem("Token");
      let auth = `Token ${token}`;
      auth = auth.replaceAll('"', "");
      if (this.questionType === "Text Answer") {
        this.questionType = "Text";
        let result = await services.AddTXQuestion(
          auth,
          this.questionText,
          this.questionType,
          this.is_discusstion,
          this.is_thematic
        );
        console.log(result);
        if (result.status === 201) {
          alert("Question Added");
          this.buttonLoading = false;
        }
      } else {
        if (
          this.Option1 === "" ||
          this.Option2 === "" ||
          this.Option3 === "" ||
          this.Option4 === ""
        ) {
          alert("Please Fill All Options");
          this.buttonLoading = false;
          return;
        } else {
          if (this.questionType === "MCQ Single Answer") {
            this.questionType = "Single";
          } else if (this.questionType === "MCQ Multiple Answer") {
            this.questionType = "Multiple";
          }
          let result = await services.AddMCQuestion(
            auth,
            this.questionText,
            this.questionType,
            this.is_discusstion,
            this.is_thematic,
            this.option1,
            this.option2,
            this.option3,
            this.option4
          );
          console.log(result);
          if (result.status === 201) {
            alert("Question Added");
            this.buttonLoading = false;
          }
        }
      }
      this.buttonLoading = false;
      this.questionText = "";
      this.questionType = "Select a Question Type";
      this.option1 = "";
      this.option2 = "";
      this.option3 = "";
      this.option4 = "";
    },

    toggleThematic() {
      if (this.is_thematic === true) {
        var ele = document.getElementsByName("thematic");
        for (var i = 0; i < ele.length; i++) ele[i].checked = false;
        this.is_thematic = false;
        console.log(this.is_thematic);
        return;
      }
      this.is_thematic = true;
    },

    toggleDiscussion() {
      if (this.is_discusstion === true) {
        var ele = document.getElementsByName("discussion");
        for (var i = 0; i < ele.length; i++) ele[i].checked = false;
        this.is_discusstion = false;
        console.log(this.is_discusstion);
        return;
      }
      this.is_discusstion = true;
    },
  },
  async mounted() {
    let token = localStorage.getItem("Token");
    if (!token) {
      this.$router.push({ name: "HomePage" });
    }
  },
  components: { ButtonLoader },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@700&family=Poppins:wght@400;500;600&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.space,
.options {
  display: block;
  margin-bottom: 30px;
}

.custom-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 320px;
  height: 57px;
  padding: 10px 38px 10px 16px;
  background-size: 10px;
  transition: border-color 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
  border: 1px solid #ddd;
  border-radius: 3px;
}
.custom-select:hover {
  border: 1px solid #999;
}
.custom-select:focus {
  border: 1px solid #999;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.2);
  outline: none;
}
/* remove default arrow in IE */
select::-ms-expand {
  display: none;
}

.bg {
  background: linear-gradient(120deg, #2980b9, #8e44ad);
}

.logo {
  opacity: 0.8;
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70px;
}

template {
  margin: 0;
  padding: 0;
  height: 100vh;
  overflow: hidden;
}

.center {
  width: 400px;
  height: 700px;
  background: white;
  border-radius: 10px;
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.05);
}

.bottom {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.bottom h6 {
  color: #333;
}

.center h1 {
  text-align: center;
  padding: 20px 0;
  border-bottom: 1px solid #f0f0f0;
}

.center .form {
  padding: 0 40px;
  box-sizing: border-box;
}

.form .txt_field {
  position: relative;
  border-bottom: 1px solid #f0f0f0;
  margin: 30px 0;
}

.txt_field input {
  width: 100%;
  padding: 0 5px;
  height: 40px;
  font-size: 16px;
  border: none;
  background: none;
  outline: none;
}

.txt_field label {
  position: absolute;
  top: 50%;
  left: 5px;
  color: #adadad;
  transform: translateY(-50%);
  font-size: 16px;
  pointer-events: none;
  transition: 0.5s;
}

.txt_field span::before {
  content: "";
  position: absolute;
  top: 40px;
  left: 0;
  width: 0%;
  height: 2px;
  background: #2691d9;
  transition: 0.5s;
}

.txt_field input:focus ~ label,
.txt_field input:valid ~ label {
  top: -5px;
  color: #2691d9;
}

.txt_field input:focus ~ span::before,
.txt_field input:valid ~ span::before {
  width: 100%;
}

.pass {
  margin: -5px 0 20px 5px;
  color: #a6a6a6;
  cursor: pointer;
}

.pass:hover {
  text-decoration: underline;
}

button {
  width: 100%;
  height: 50px;
  border: 1px solid;
  background: #2691d9;
  border-radius: 25px;
  font-size: 18px;
  color: #e9f4fb;
  font-weight: 700;
  cursor: pointer;
  outline: none;
}

button:hover {
  border-color: #2691d9;
  transition: 0.5s;
}

.signup_link {
  margin: 30px 0;
  text-align: center;
  font-size: 16px;
  color: #666666;
}

.signup_link a {
  color: #2691d9;
  text-decoration: none;
}

.signup_link a:hover {
  text-decoration: underline;
}

.link a {
  color: #2691d9;
  text-decoration: none;
}

.link a:hover {
  text-decoration: underline;
}

input[type="radio"] {
  width: 16px;
  height: 16px;
  margin-right: 20px;
}

input[type="radio"]:checked {
  background: #2691d9;
}
</style>
