<template>
  <div class="body">
    <div class="hero">
      <img src="@/assets/logo.svg" alt="idol" class="logo" />
    </div>
    <h1>Welcome to Admin Panel, {{ name }}</h1>
    <div class="center">
      <div class="navbutton">
        <button @click="routeToAllQuestion">All Questions</button>
        <button @click="routeToAllResponse">All Answers</button>
        <button @click="routeToAddQuestion">Add Question</button>
        <button @click="wipeSession">Wipe Session</button>
      </div>
    </div>
    <div class="bottom">
      <p class="logoutbtn" @click="Logout">Logout</p>
      <h6>@ Idol, 2022</h6>
    </div>
  </div>
</template>

<script>
import services from "@/services/services.js";
export default {
  name: "AdminView",
  data() {
    return {
      name: "",
    };
  },
  methods: {
    Logout() {
      localStorage.clear("Token");
      this.$router.push({ name: "HomePage" });
    },
    async wipeSession() {
      let token = localStorage.getItem("Token");
      let auth = `Token ${token}`;
      auth = auth.replaceAll('"', "");
      let result = await services.deleteEverything(auth);
      if (result.status == 200) {
        alert("Session Wiped");
      } else {
        alert("Something Went Wrong");
      }
    },
    routeToAllQuestion() {
      this.$router.push({ name: "AdminQuestions" });
    },
    routeToAllResponse() {
      this.$router.push({ name: "AdminAnswers" });
    },
    routeToAddQuestion() {
      this.$router.push({ name: "AddQuestions" });
    },
    routeToEditQuestion() {
      this.$router.push({ name: "EditQuestions" });
    },
  },
  async mounted() {
    let token = localStorage.getItem("Token");
    if (!token) {
      this.$router.push({ name: "LoginPage" });
      return;
    }
    let auth = `Token ${token}`;
    auth = auth.replaceAll('"', "");
    let result = await services.getUser(auth);
    if (result.data.is_admin === false) {
      this.$router.push({ name: "HomePage" });
      return;
    }
    this.name = result.data.name;
  },
};
</script>

<style scoped>
.logo {
  opacity: 0.8;
  width: 70px;
}

.hero {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 40%;
  margin-bottom: 40px;
}

.start {
  margin-top: 40px;
}

AdminNav {
  position: relative;
}

.center {
  display: flex;
  margin-top: 5%;
  margin-bottom: 5%;
}
.navbutton {
  display: grid;
  grid-template-areas:
    "button1"
    "button2"
    "button3";
  grid-gap: 20px;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
  height: 80%;
}

.bottom {
  color: #222;
  position: relative;
  bottom: -40px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.logoutbtn {
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bolder;
  color: #2691d9;
}

.body {
  margin: 80px;
}

.body h1 {
  font-size: 20px;
}

* {
  box-sizing: border-box;
}

body {
  background-color: #b8c6db;
  background-image: linear-gradient(315deg, #b8c6db 0%, #f5f7f7 100%);
  font-family: "Poppins", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  overflow: hidden;
  margin: 0;
}

button {
  width: 100%;
  height: 100%;
  border: 1px solid;
  background: #2691d9;
  border-radius: 25px;
  font-size: 18px;
  color: #e9f4fb;
  font-weight: 700;
  cursor: pointer;
  outline: none;
}

button:hover {
  border-color: #2691d9;
  transition: 0.5s;
}
</style>
